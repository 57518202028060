import React, { Component } from "react";
import { connect } from "react-redux";

import { apiBaseUrl } from "../../store/helpers/common";
import SimpleReactValidator from "simple-react-validator";
import { NavLink } from "react-router-dom";
import { getLog } from "../../store/actions/commonActions";

class AdminViewLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      log: "",
    };
  }

  componentDidMount() {
    this.props.dispatch(getLog());
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      leadsdata: nextProps.leadsdata,
      log: nextProps.log,
    });
  }

  render() {
    const { log } = this.state;
    return (
      <>
        <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-12">
            <h2>Leads</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink className="nav-link" to="/dashboard">
                  Dashboard
                </NavLink>
              </li>
              <li className="breadcrumb-item active">
                <NavLink className="nav-link" to="/view-log">
                  VRT Data Refresh Log
                </NavLink>
              </li>
            </ol>
          </div>
          <div className="col-lg-2"></div>
        </div>
        <div className="wrapper wrapper-content animated fadeInRight">
          <div className="row">
            <div className="col-lg-12">
              <div className="ibox ">
                <div className="ibox-title">
                  <h3>VRT Data Refresh Log</h3>
                  <div className="ibox-tools"></div>
                </div>
                <div className="ibox-conten scroll-content">
                  {this.props.logloading ? (
                    <div className="alltourticksloader">
                      <img
                        className="loader_img"
                        src="/assets/images/straight-loader.gif"
                      />
                    </div>
                  ) : log ? (
                    
                    <p><pre>{JSON.stringify(log, null, 2) }</pre></p>
                  ) : (
                    <div>No Logs</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  log: state.common.log,
  logloading: state.common.logloading,
});

export default connect(mapStateToProps) (AdminViewLog);

