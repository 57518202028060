
//Live API base
export const apiBaseUrl = "https://api.ukcarimports.ie/public";

//Local API base
// export const apiBaseUrl = "http://localhost:8000";

// Stagging API baseurl
// export const apiBaseUrl = "https://api.staging.ukcarimports.ie/public";

export function gameName(gname) {
  if (gname === "overwatch") {
    return "OVERWATCH";
  } else {
    return "";
  }
}
export default function parseJwt(token) {
  if (!token) {
    return;
  }
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

